<template>
  <b-row>
    <b-col cols="12">

      <b-overlay
          :show="tableLoading"
          rounded="sm"
      >

      <b-table
          :current-page="currentPage"
          :fields="fields"
          :items="getLocums"
          :per-page="pagination.perPage"
          ref="table"
          class="mobile_table_css"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @row-clicked="navigateApplierProfile"
          hover
          responsive
      >

<!--        <template #cell(name)="data">-->
<!--          <ul class="list-inline mb-0">-->
<!--            <li class="list-inline-item">-->
<!--              <b-avatar :src="getUserImage(data.item.id, true)" class="pull-up "/>-->
<!--            </li>-->
<!--            <li class="list-inline-item"> {{ data.item.name }}</li>-->
<!--            <b-badge class="ml-1" style="padding:8px" variant="light-info">-->
<!--            </b-badge>-->
<!--          </ul>-->
<!--        </template>-->
      </b-table>
      </b-overlay>
    </b-col>

    <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
      <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.totalRows }} entries</span>
    </b-col>

    <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

      <b-pagination
          v-model="currentPage"
          :per-page="pagination.perPage"
          :total-rows="pagination.totalRows"
          class="mb-0 mt-1 mt-sm-0 "
          first-number
          last-number
          next-class="next-item"
          prev-class="prev-item">

        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18"/>
        </template>

        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18"/>
        </template>
      </b-pagination>
    </b-col>
  </b-row>
</template>

<script>
import ApiLocum from '@/apis/modules/locums.js'
import MomentMixin from '@/mixins/MomentMixin'

import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BOverlay
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BOverlay
  },
  mixins: [MomentMixin],

  data() {
    return {
      tableLoading: false,
      declinedLocums: [],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      // fields: [
      //   {key: "user.first_name", label: "Name"},
      //   {key: "job_role.name", label: "Role"},
      //   {key: "fee", label: "Fee"},
      //   {key: "user.created_at", label: "Registered Date"},
      // ],
      fields: [
        {
          key: 'first_name',
          label: 'NAME',
        },
        {
          key: 'email',
          label: 'EMAIL',
        },
        {
          key: 'telephone',
          label: 'telephone'
        },
        {
          key: 'account_created',
          label: 'Date account created',
        }
      ],
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
    }
  },

  methods: {
    navigateApplierProfile(item) {
      this.$router.push(`/locum-profile/${item.id}`)
    },
    async getLocums() {
      try {
        this.tableLoading = true
        const Response = (await ApiLocum.getLocums(this.currentPage, 10,'declined', ))
        console.log(Response)
        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          first_name: x.user.first_name +' '+ x.user.last_name,
          email: x.user.email,
          telephone: x.user.phone_number ? x.user.phone_number : 'N/A',
          account_created: this.momentFormat(x.created_at, 'ddd DD MM YYYY'),
          job_role: x.job_role.name,


        }))
        const paginationResponse = Response.data
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false
        return dataArray


        // const response = (await ApiLocum.getLocums('under review')).data.data
        //  console.log(this.underReviewLocums);
        //  let users
      } catch (e) {
        this.tableLoading = false
      }
      // if (Response.data) {
      //   const paginationResponse = Response.data.meta
      //   this.currentPage = paginationResponse.current_page
      //   this.pagination.totalRows = paginationResponse.total
      //   this.pagination.perPage = paginationResponse.per_page
      //   this.pagination.from = paginationResponse.from
      //   this.pagination.to = paginationResponse.to
      // }
    }
  },

  async mounted() {
    // await this.getLocums()
  }
}
</script>




