<template>
  <div class="home">
    <b-card class="mp-0">

      <b-tabs>
        <b-tab title="Under Review" lazy>
          <div class="alert-body">
            <UnderReview/>
          </div>
        </b-tab>

        <b-tab title="Approved" lazy>
          <div class="alert-body">
            <Approve/>
          </div>
        </b-tab>

        <b-tab title="Declined" lazy>
          <div class="alert-body">
            <Declined/>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>

import UnderReview from './view/underReview'
import Declined from './view/Declined'
import Approve from './view/Approve'

import {BCard, BTabs, BTab, BButton,} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'Home',
  components: {
    UnderReview,
    Declined,
    Approve,
    BCard,
    BTabs,
    BTab,
    BButton
  },
  directives: {
    Ripple,
  },

}
</script>
